"use client";
import { Builder } from "@builder.io/react";
import ReactPlayer from "react-player";
import clsx from "clsx";
import React from "react";
import Button from "../UI/Button/Button";
import CompressedImage from "../UI/CompressedImage/CompressedImage";
import Markdown from "../UI/Markdown/Markdown";
import styles from "./HeroBanner.module.scss";

const backgroundImageStates = [
	"Background Off",
	"Background Cardinal",
	"Background Cardinal 2",
];

const backgroundImages = {
	[backgroundImageStates[0]]: styles.noBackground,
	[backgroundImageStates[1]]: styles.cardinal,
	[backgroundImageStates[2]]: styles.cardinal2,
};

interface HeroBannerProps {
	isMainHero: boolean;
	title: string;
	regionTitle?: string;
	addSubtitle: boolean;
	subtitle: string;
	description?: string;
	backgroundImageState: string;
	backgroundColor: string;
	image: {
		isVideo: boolean;
		imageCurveBottom: boolean;
		imageCurveTop: boolean;
		src?: string;
		videoSrc?: string;
		videoFile?: string;
		altText?: string;
		imgLink?: string;
		autoPlayVideo: boolean;
		posterImage?: string;
	};
	button: {
		showButton: boolean;
		btnText?: string;
		btnLink?: string;
		ariaLabel?: string;
	};
}

function HeroBanner({
	isMainHero,
	title,
	regionTitle,
	addSubtitle,
	subtitle,
	description,
	image,
	button,
	backgroundImageState,
	backgroundColor,
}: HeroBannerProps) {
	const ButtonHTML = () => {
		const {
			showButton,
			btnText = "Button",
			btnLink = "#",
			ariaLabel,
		} = button || {};

		if (!showButton) return null;

		return (
			<Button
				variant='primary'
				as='link'
				href={btnLink}
				aria-label={ariaLabel || btnText || ""}
				className={styles.button}
			>
				{btnText}
			</Button>
		);
	};

	const ImageHTML = () => {
		const fallbackImageUrl = "https://placehold.co/800x500/webp";
		const {
			isVideo,
			imageCurveBottom,
			imageCurveTop,
			src = fallbackImageUrl,
			videoSrc,
			videoFile,
			imgLink,
			altText = "",
			autoPlayVideo,
			posterImage,
		} = image || {};

		// Work out if this is an image or a video:
		if (isVideo) {
			return (
				<div
					className={clsx(styles.imageContainer, {
						[styles.imageCurveBottom]: imageCurveBottom,
						[styles.imageCurveTop]: imageCurveTop,
						[styles.imageCurveTopBottom]: imageCurveBottom && imageCurveTop,
					})}
				>
					<ReactPlayer
						url={videoSrc || videoFile}
						playing={autoPlayVideo}
						volume={autoPlayVideo ? 0 : 1}
						light={autoPlayVideo ? false : posterImage}
						loop={!!videoFile?.length}
						playsinline
					/>
				</div>
			);
		}

		return (
			<CompressedImage
				src={`${src}?format=webp`}
				height={500}
				width={800}
				altText={altText}
				imgLink={imgLink}
				containerClass={clsx(styles.imageContainer, {
					[styles.imageCurveBottom]: imageCurveBottom,
					[styles.imageCurveTop]: imageCurveTop,
					[styles.imageCurveTopBottom]: imageCurveBottom && imageCurveTop,
				})}
				loading={"eager"}
			/>
		);
	};

	const RegionTitleJSX = () => (
		<span className={styles.regionTitle}>
			<br />
			{regionTitle}
		</span>
	);

	return (
		<section
			className={clsx(styles.section, backgroundImages[backgroundImageState])}
			style={{ backgroundColor }}
		>
			<div className={clsx([styles.banner])}>
				<div className={styles.content}>
					{isMainHero ? (
						<h1 className={styles.title}>
							{title}
							{regionTitle ? <RegionTitleJSX /> : null}
						</h1>
					) : (
						<h2 className={styles.title}>
							{title}
							{regionTitle ? <RegionTitleJSX /> : null}
						</h2>
					)}
					{addSubtitle && subtitle && (
						<Markdown className={styles.subTitle} content={subtitle} />
					)}
					{description && (
						<Markdown className={styles.description} content={description} />
					)}
					<ButtonHTML />
				</div>
				<ImageHTML />
			</div>
		</section>
	);
}

export default HeroBanner;

Builder.registerComponent(HeroBanner, {
	name: "Hero Banner",
	inputs: [
		{
			name: "isMainHero",
			type: "boolean",
			friendlyName: "Main Hero?",
			defaultValue: false,
			helperText: "Main Hero Enabled = Title H1 | Disabled = Title H2",
		},
		{
			name: "title",
			type: "string",
			defaultValue: "This is a title",
			required: true,
			helperText: "~25 characters",
		},
		{
			name: "regionTitle",
			type: "string",
			defaultValue: "",
			required: false,
			helperText:
				"Displays red title text. Leave empty if not required. | ~10 characters",
		},
		{
			name: "addSubtitle",
			type: "boolean",
			friendlyName: "Add Subtitle",
			defaultValue: false,
			helperText: "Enable Subtitle Display",
		},
		{
			name: "subtitle",
			type: "richText",
			defaultValue: "<h2>This is an optional sub title</h2>",
			showIf: `options.get('addSubtitle')`,
			helperText: "Subtitle is a H2 | ~30 characters",
		},
		{
			name: "description",
			type: "richText",
			defaultValue: "This is an optional piece of descriptive text",
			helperText: "H5 (font size 16px) | ~225 characters",
		},
		{
			name: "button",
			type: "object",
			subFields: [
				{
					name: "showButton",
					type: "boolean",
					friendlyName: "Show button?",
					defaultValue: true,
					helperText: "Enabled to display a button under the description",
				},
				{
					name: "btnText",
					type: "string",
					friendlyName: "Text",
					defaultValue: "Button",
					required: true,
					showIf: `options.get('showButton')`,
				},
				{
					name: "btnLink",
					type: "string",
					friendlyName: "Link",
					defaultValue: "#",
					required: true,
					showIf: `options.get('showButton')`,
				},
				{
					name: "ariaLabel",
					type: "string",
					friendlyName: "Aria-label",
					defaultValue: "Go to here",
					required: true,
					showIf: `options.get('showButton')`,
				},
			],
		},
		{
			name: "backgroundImageState",
			friendlyName: "Background Image state",
			type: "string",
			defaultValue: "Background Cardinal",
			enum: backgroundImageStates,
		},
		{
			name: "backgroundColor",
			friendlyName: "Background Color",
			type: "color",
			defaultValue: "#FFFFFF",
		},
		{
			name: "image",
			type: "object",
			subFields: [
				{
					name: "isVideo",
					friendlyName: "Video?",
					type: "boolean",
					defaultValue: false,
					helperText: "Enabled to use Video as the Hero Image",
				},
				{
					name: "imageCurveBottom",
					friendlyName: "Add Bottom Image Curve?",
					type: "boolean",
					defaultValue: true,
					helperText: "Toggle to add a curve to the bottom of the image",
				},
				{
					name: "imageCurveTop",
					friendlyName: "Add Top Image Curve?",
					type: "boolean",
					defaultValue: false,
					helperText: "Toggle to add a curve to the top of the image",
				},
				{
					name: "src",
					type: "file",
					friendlyName: "Hero image",
					defaultValue: "https://placehold.co/800x500/webp",
					helperText: "800×500 px | .jpeg, .jpg, .png, .gif | ~300 KB",
					allowedFileTypes: ["jpeg", "jpg", "png", "gif"],
					showIf: `!options.get('isVideo')`,
				},
				{
					name: "videoSrc",
					type: "text",
					friendlyName: "Hero video",
					defaultValue:
						"https://www.youtube.com/embed/NNS5Piu-EII?si=zuIzEK7AkP3oY22W",
					helperText: "1600×1000 px | YouTube/Vimeo video link",
					showIf: `options.get('isVideo')`,
				},
				{
					name: "videoFile",
					type: "file",
					friendlyName: "Hero MP4",
					defaultValue: "https://2050today.org/video-placeholder-mp4/",
					helperText:
						"Ensure Hero Video field is blank 800×500 px | .mp4 | ~3 MB",
					allowedFileTypes: ["mp4"],
					showIf: `options.get('isVideo')`,
				},
				{
					name: "autoPlayVideo",
					type: "boolean",
					defaultValue: true,
					showIf: `options.get('isVideo')`,
					helperText: "Enabled video to autoplay on page load",
				},
				{
					name: "posterImage",
					type: "file",
					friendlyName: "Video Poster Image",
					defaultValue: "https://placehold.co/800x500/webp",
					helperText: "800×500 px | .jpeg, .jpg, .png, .gif | ~300 KB",
					allowedFileTypes: ["jpeg", "jpg", "png", "gif"],
					showIf: `options.get('isVideo')`,
				},
				{
					name: "altText",
					type: "string",
					friendlyName: "Alt text",
					defaultValue: "e.g. The two dogs run",
					required: true,
				},
				{
					name: "imgLink",
					type: "string",
					friendlyName: "Link",
					defaultValue: "#",
					helperText: "Link added to Image when clicked",
				},
			],
		},
	],
});
