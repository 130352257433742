"use client";

import React, {
	useContext,
	useEffect,
	useId,
	useMemo,
	useRef,
	useState,
} from "react";
import { Builder } from "@builder.io/react";
import SwiperType from "swiper";
import clsx from "clsx";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Navigation, Scrollbar } from "swiper/modules";
import { getSearchSpringClient } from "@/lib/searchspring/client";
import useStoreKeys from "@/hooks/useStoreKeys";
import { ProductData } from "@/lib/5874/types";
import useCurrentPath from "@/hooks/useCurrentPath";
import { ProductContextProvider } from "@/providers/ProductContextProvider";
import Pricing from "../ProductCard/Pricing/Pricing";
import SwiperNavigationButtons from "../SwiperNavigationButton/SwiperNavigationButton";
import Button from "../UI/Button/Button";
import IconOnlyButton from "../UI/Button/IconOnlyButton/IconOnlyButton";
import ProductSliderSkeleton from "../UI/Skeleton/ProductSliderSkeleton";
import PlaceholderImage from "@/app/assets/images/img/placeholder.png";
import PlusIcon from "@/app/assets/images/svg/plus.svg";
import styles from "./ProductCarousel.module.scss";
import { useDataLayerContext } from "@/datalayer/DataLayerContextProvider";
import { BigCommerceStoreContext } from "@/providers/BigCommerceStoreProvider";
import useBeaconClientArgs from "@/hooks/useBeaconClientArgs";
import { ProductAvailability } from "@/lib/bigcommerce/enums";
import {
	AdditionalArgs,
	beaconTrackProductClick,
	beaconTrackProductsImpression,
	beaconTrackProductsRender,
	CommonArgs,
	genericSendBeaconEvent,
	getNewlyVisibleProduct,
	getVisibleProducts,
	PlacementType,
} from "@/lib/searchspring/tracker";
import { StoreRegion } from "@/lib/builder/builder.config";
import ProductCard from "../ProductCard/ProductCard";

interface Props {
	title: string;
	profileTag: string;
	type?: "full" | "mini";
}

const ProductCarousel: React.FC<Props> = ({
	title,
	profileTag,
	type = "full",
}) => {
	const { store, lang } = useStoreKeys();

	const [products, setProducts] = useState<Array<ProductData> | undefined>();
	const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

	useEffect(() => {
		setShowLoadingSpinner(true);
		const getRecommends = async () => {
			const client = getSearchSpringClient(store, lang);

			const res = await client.enrichedRecommend({
				siteId: client.siteId,
				tag: profileTag,
				limits: 16,
			});
			if (res) {
				setProducts(res.results);
				setShowLoadingSpinner(false);
			}
		};

		if (profileTag) {
			getRecommends();
		} else {
			setProducts(undefined);
		}
	}, [profileTag]);

	switch (type) {
		case "mini":
			return (
				<MiniCarousel
					title={title}
					products={products}
					profileTag={profileTag}
				/>
			);
		default:
			return (
				<FullCarousel
					title={title}
					products={products}
					showLoadingSpinner={showLoadingSpinner}
					profileTag={profileTag}
				/>
			);
	}
};

interface CarouselProps {
	title: string;
	products: Array<ProductData> | undefined;
	showLoadingSpinner?: boolean;
	profileTag: string;
}

const FullCarousel = ({
	title,
	products,
	showLoadingSpinner,
	profileTag,
}: CarouselProps) => {
	const { store, lang } = useStoreKeys();
	const carouselId = useId();

	const { viewItemList, selectItem } = useDataLayerContext();
	const { currencyFormattingRules } = useContext(BigCommerceStoreContext);

	const { siteId, pageLoadId, ssCookieData } = useBeaconClientArgs();
	const availableProducts =
		products?.filter((p) => p.availability === ProductAvailability.AVAILABLE) ??
		[];
	const [swiper, setSwiper] = useState<SwiperType | undefined>();
	const [inViewport, setInViewport] = useState(false);
	const previousVisibleSlidesRef = useRef<number[]>([]);
	const seedData = availableProducts.map((p) => String(p.id));

	// Datalayer
	useEffect(() => {
		if (products?.length) {
			viewItemList(
				products.filter((p) => !p.isNotPurchasable),
				profileTag,
				currencyFormattingRules.currency ?? "",
				title,
			);
		}
	}, [products]);

	// Beacon payload
	const payload = {
		products: availableProducts,
		ssCookieData,
		siteId,
		pageLoadId,
		tag: profileTag,
		placement: "product-page" as PlacementType,
		skus: seedData,
		placementProductId: "",
	};

	useEffect(() => {
		if (swiper && swiper.el && availableProducts) {
			swiper.update();
			if (Builder.isEditing) return;

			const observer = new IntersectionObserver((entries) => {
				if (entries.some((entry) => entry.isIntersecting)) {
					setInViewport(true);
				}
			});
			observer.observe(swiper.el);
		}
	}, [swiper, availableProducts]);

	useEffect(() => {
		if (
			availableProducts?.length &&
			swiper &&
			swiper.slides &&
			!Builder.isEditing
		) {
			const renderEvents = beaconTrackProductsRender(payload);
			genericSendBeaconEvent(renderEvents, store);
		}
	}, [availableProducts]);

	useEffect(() => {
		if (swiper && inViewport && !Builder.isEditing && availableProducts) {
			const visibleProducts = getVisibleProducts(availableProducts, swiper);

			if (visibleProducts?.length && products !== undefined) {
				const impressionEvents = beaconTrackProductsImpression({
					...payload,
					products: getVisibleProducts(availableProducts, swiper) ?? products,
				});

				genericSendBeaconEvent(impressionEvents, store);
			}
		}
	}, [inViewport, availableProducts, swiper?.slides]);

	// Beacon click product event
	const profileClickData: CommonArgs & AdditionalArgs = useMemo(
		() => ({
			siteId,
			pageLoadId,
			ssCookieData,
			tag: process.env.SEARCH_SPRING_ID ?? "",
			placement: "other" as PlacementType,
			skus: seedData,
			placementProductId: "",
		}),
		[],
	);

	const productClickPayload = (product: ProductData) => {
		return {
			siteId,
			pageLoadId,
			ssCookieData,
			tag: profileTag ?? "",
			placement: "product-page" as PlacementType,
			product,
			skus: undefined,
			placementProductId: "",
			href: product.path,
		};
	};

	// Experimental
	const handleSlideChange = (swiper: SwiperType | undefined) => {
		if (!swiper) return;
		const slidesPerView = Number(swiper.params.slidesPerView);

		const visibleSlides = swiper.slides
			.map((slide, index) => {
				const isVisible =
					index >= swiper.activeIndex &&
					index < Number(swiper.activeIndex) + slidesPerView;
				return isVisible ? index : null;
			})
			.filter((index): index is number => index !== null);

		const previousVisibleSlides = previousVisibleSlidesRef.current;
		const newlyVisibleSlides = visibleSlides.filter(
			(index) => !previousVisibleSlides.includes(index),
		);

		if (newlyVisibleSlides?.length > 0) {
			const newProducts = getNewlyVisibleProduct(products, newlyVisibleSlides);

			if (newProducts?.length) {
				const events = beaconTrackProductsImpression({
					...payload,
					products: newProducts,
				});
				genericSendBeaconEvent(events, store);
			}
		}
		previousVisibleSlidesRef.current = visibleSlides;
	};

	return (
		<section className={clsx([styles.section])}>
			<h2 className={styles.title}>{title}</h2>
			<Swiper
				onSwiper={(el) => {
					setSwiper(el);
				}}
				watchSlidesProgress={true}
				onSlideChange={(swiper) => handleSlideChange(swiper)}
				modules={[Scrollbar, Navigation, A11y]}
				scrollbar={true}
				navigation={{
					enabled: true,
					nextEl: `[data-button-ref="${carouselId}-button-next"]`,
					prevEl: `[data-button-ref="${carouselId}-button-previous"]`,
				}}
				spaceBetween={16}
				breakpoints={{
					1000: {
						slidesPerView: 4,
					},
					700: {
						slidesPerView: 2,
					},
					551: {
						slidesPerView: 1,
					},
				}}
			>
				{showLoadingSpinner
					? ProductSliderSkeleton
					: products?.map((product) => {
							return (
								<SwiperSlide
									key={product.id}
									className={styles.slide}
									onClick={() => {
										selectItem(
											product,
											product.id,
											carouselId,
											product?.categories[0]?.name ?? carouselId,
											currencyFormattingRules.currency,
										);
										const payload = productClickPayload(product);
										beaconTrackProductClick(payload, store);
									}}
								>
									<ProductCard
										product={product}
										currencyCode={currencyFormattingRules.currency}
										showSimpleView={true}
									/>
								</SwiperSlide>
							);
					  })}
				<SwiperNavigationButtons
					buttonIdentifier={carouselId}
					clickData={profileClickData}
					store={store}
				/>
			</Swiper>
		</section>
	);
};

const MiniCarousel = ({ title, products }: CarouselProps) => {
	const { store, lang } = useStoreKeys();
	const carouselId = useId();
	const currentPath = useCurrentPath();

	const isCustomer = true; //session !== null;

	return (
		<section className={styles.mini}>
			{title && <h2 className={styles.title}>{title}</h2>}
			<Swiper
				modules={[Scrollbar, Navigation, A11y]}
				scrollbar={true}
				navigation={{
					enabled: true,
					nextEl: `[data-button-ref="${carouselId}-button-next"]`,
					prevEl: `[data-button-ref="${carouselId}-button-previous"]`,
				}}
				slidesPerView={"auto"}
				spaceBetween={16}
			>
				{products?.map((product) => {
					const thumbnail =
						product.media.images?.find((image) => image.isThumbnail) ||
						product.media.images?.[0];

					return (
						<SwiperSlide key={product.id} className={styles.slide}>
							<ProductContextProvider product={product}>
								<article key={product.id} className={clsx([styles.card])}>
									<div className={styles.inner}>
										<img
											src={
												`${thumbnail?.url?.thumbnail}?format=webp` ||
												PlaceholderImage.src
											}
											alt={`Product ${product.name}`}
										/>
										<div className={styles.details}>
											{product.brand && (
												<Link
													className={styles.brand}
													href={`/${store}/${lang}${product.brand.path}`}
												>
													{product.brand.path}
												</Link>
											)}
											<Link
												href={`/${store}/${lang}${product.path}`}
												className={styles.name}
											>
												{product.name}
											</Link>
											{!isCustomer ? (
												<Link
													href={`/${store}/${lang}/login?redirect=${currentPath}`}
													className={styles.login}
												>
													Log in for pricing
												</Link>
											) : (
												<Pricing className={styles.price} />
											)}
										</div>
										<div className={styles.ctaDesktop}>
											<IconOnlyButton
												variant='primary'
												icon={<PlusIcon />}
												type='submit'
											/>
										</div>
									</div>
									<div className={styles.ctaMobile}>
										<Button as='button' variant='primary'>
											Add to cart
										</Button>
									</div>
								</article>
							</ProductContextProvider>
						</SwiperSlide>
					);
				})}
				<SwiperNavigationButtons buttonIdentifier={carouselId} />
			</Swiper>
		</section>
	);
};

export default ProductCarousel;

Builder.registerComponent(ProductCarousel, {
	name: "SearchSpring Recommendations Carousel",
	inputs: [
		{
			name: "title",
			friendlyName: "Title",
			type: "longText",
			defaultValue: "Title",
		},
		{
			name: "profileTag",
			friendlyName: "SearchSpring Profile Tag",
			type: "longText",
			defaultValue: "also-viewed",
		},
	],
});

function useNewImpressions(
	products: ProductData[] | undefined,
	payload: any,
	store: StoreRegion,
) {
	const [newSlides, setNewSlides] = useState<number[]>([]);
	useEffect(() => {
		if (newSlides.length > 0) {
			// Fire impression events on newSlide
			const newProducts = getNewlyVisibleProduct(products, newSlides);

			if (newProducts !== undefined) {
				const events = beaconTrackProductsImpression({
					...payload,
					products: newProducts,
				});
				genericSendBeaconEvent(events, store);
			}
		}
		return () => {};
	}, [newSlides]);

	return null;
}

function useFirstImpressions(
	products: ProductData[] | undefined,
	swiper: SwiperType | undefined,
	payload: any,
	store: StoreRegion,
) {
	useEffect(() => {
		if (products && swiper && swiper.slides && !Builder.isEditing) {
			const renderEvents = beaconTrackProductsRender(payload);
			const impressionEvents = beaconTrackProductsImpression({
				...payload,
				products: getVisibleProducts(products, swiper),
			});
			genericSendBeaconEvent([...renderEvents, ...impressionEvents], store);
		}
		return () => {};
	}, [products]);

	return null;
}
